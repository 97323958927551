import React from "react"

// Components
import Box from "./Box"

// Images
import footFella from "../images/footfella.gif"

const Fella = props =>

<Box
  width={[128, 200]}
  height={[128, 200]}
  style={{
    background: 'linear-gradient(-45deg, #C62025, #F2CB03, #4AB373, #0F5FC5 ) 0% 0% / 400% 400%',
    animation: '8s ease 0s infinite normal none running gradient',
    clipPath: 'circle(50%)'
  }}
>
  <img src={footFella} alt="FootFellaz" loop="infinite" />
</Box>

export default Fella
