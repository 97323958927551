import React from "react"

// Components
import Container from "./Container"
import Flex from "./Flex"
import Fella from "./Fella"
import Heading from "./Heading"
import Text from "./Text"
import StyledLink from "./StyledLink"
import GradientText from "./GradientText"

const Intro = props =>

<Flex
  py={[5, 6]}
  bg="gray3"
  width={1}
  minHeight="100vh"
  flexDirection="column"
  justifyContent="center"
>
  <Container>
    <Flex

      width={1}
      flexDirection="column"
      justifyContent="center"
      alignItems={['center', 'flex-start']}
    >
      <Fella />
      <Heading
        as="h1"
        mb={6}
        color="white"
        fontSize={[5, 6, 7, 8]}
        textAlign={['center', 'left']}
      > An NFT collection of <GradientText>3,245</GradientText> undead footballers who compete to win season long prizes. </Heading>
      <Text
        color="white"
        fontSize={[1, 1, 2]}
        maxWidth={["100%", "768px"]}
      >
        This project is not affiliated with <StyledLink href="https://www.deadfellaz.io/" title="Deadfellaz" target="_blank" rel="noopener noreferrer">Deadfellaz</StyledLink>. More info soon.
      </Text>
    </Flex>
  </Container>
</Flex>

export default Intro
