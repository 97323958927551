import styled from '@emotion/styled'

import {
  space,
  color,
  layout
} from 'styled-system'

const Box = styled.div`
  ${space}
  ${color}
  ${layout};
`

export default Box
