import styled from '@emotion/styled'

import { flexbox } from 'styled-system'

import Box from './Box'

const Flex = styled(Box)`
  ${flexbox};
  display: flex;
`

export default Flex
