import React from "react"

import { ThemeProvider } from '@emotion/react'
import theme from "../components/theme"

export default function Layout({ children }) {
  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  )
}
