import React from "react"
import styled from '@emotion/styled'

// Components
import Flex from "./Flex"
import Social from "./Social"


const StickyBar = styled(Flex)`
  position: fixed;
  z-index: 999;
  top: 0;
  width: 100%;
`

const NavBar = props =>

<StickyBar
  width={1}
  py={[4, 6]}
  px={[4, 6]}
  flexDirection="row"
  justifyContent="flex-end"
  alignItems="center"
>
  <Social />
</StickyBar>

export default NavBar
