import React from "react"
import styled from '@emotion/styled'

// Components
import Box from "./Box"

const StyledBox = styled(Box)`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px;
`

export default function Container({ children }) {
  return (
    <StyledBox
      px={[4, 6, 8]}
    >
      {children}
    </StyledBox>
  )
}
