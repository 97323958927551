import styled from '@emotion/styled'

import {
  color,
  typography
} from 'styled-system'

import Box from './Box'

const Text = styled(Box)`
  ${color}
  ${typography};
  display: inline-block;
`

Text.defaultProps = {
  color: 'white',
  fontSize: [1, 2],
  fontFamily: 'inter',
  fontWeight: 'regular',
  lineHeight: 'body'
}

export default Text
