import React from "react"

// Components
import Flex from "./Flex"
import StyledLink from "./StyledLink"

import {
  FaTwitter,
  FaDiscord
} from 'react-icons/fa'

const NavBar = props =>

<Flex>
  <StyledLink
    href="https://twitter.com/footfellaz"
    target="_blank"
    title="Twitter"
    rel="noopener noreferrer"
  >
    <Flex
      ml={[2, 3]}
      height="32px"
      width="32px"
      alignItems="center"
      justifyContent="center"
    >
      <FaTwitter
        style={{
          width: "24px",
          height: "24px"
        }}
      />
    </Flex>
  </StyledLink>

  <StyledLink
    href="https://discord.gg/K3aFJG55"
    target="_blank"
    title="Discord"
    rel="noopener noreferrer"
  >
    <Flex
      ml={[2, 3]}
      height="32px"
      width="32px"
      alignItems="center"
      justifyContent="center"
    >
      <FaDiscord
        style={{
          width: "24px",
          height: "24px"
        }}
      />
    </Flex>
  </StyledLink>
</Flex>

export default NavBar
