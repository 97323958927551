// theme.js

export default {
  colors: {
    green: '#4AB373',
    gray1: '#8F8F8F',
    gray2: '#292929',
    gray3: '#141414',
    white: '#FFFFFF',
    black: '#000000',
  },
  space: [
    0, 4, 8, 16, 24, 32, 48, 64, 96, 128
  ],
  fonts: {
    inter: '"Inter", sans-serif',
    spline: '"Spline Sans", sans-serif'
  },
  fontSizes: [
    12, 16, 20, 24, 32, 40, 48, 64, 72, 96
  ],
  fontWeights: {
    regular: '400',
    bold: '700',
  },
  lineHeights: {
    body: '1.5',
    heading: '1.25',
  },
}
