import styled from '@emotion/styled'

import Heading from "./Heading"

const GradientText = styled(Heading)`
  display: inline;
  background: linear-gradient(-45deg, #C62025, #F2CB03, #4AB373, #0F5FC5 ) 0% 0% / 200% 200%;
  background-clip: text;
  color: transparent;
  animation: 8s ease 0s infinite normal none running gradient;
`

export default GradientText
